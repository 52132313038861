<template>
  <v-dialog max-width="450" v-model="dialog">

    <v-card>
      <form>
        <v-card-title>
          <span class="headline">Set password for {{ item.first }} {{ item.last }}</span>
        </v-card-title>

        <v-card-text>
          <v-container grid-list-md>
            <input
                :value="item.username"
                autocomplete="username email"
                id="username"
                style="display: none;" type="text">
            <v-row>
              <v-text-field
                  :error-messages="passwordErrors"
                  :type="showPassword ? 'text' : 'password'"
                  @blur="$v.password.$touch()"
                  @input="$v.password.$touch()"
                  autocomplete="new-password"
                  counter
                  filled
                  hint="At least 8 characters"
                  label="New password"
                  v-model="password"
              ></v-text-field>
            </v-row>
            <v-row>
              <v-text-field
                  :error-messages="confirmErrors"
                  :type="showPassword ? 'text' : 'password'"
                  @blur="$v.confirm.$touch()"
                  @input="$v.confirm.$touch()"
                  autocomplete="new-password"
                  counter
                  filled
                  label="Confirm new password"
                  v-model="confirm"
              ></v-text-field>
            </v-row>
            <v-row no-gutters>
              <v-switch
                  class="py-0 my-0"
                  color="primary"
                  dense
                  label="Show passwords"
                  v-model="showPassword"
              ></v-switch>
            </v-row>
          </v-container>

          <v-alert :type="alertType" :value="(alertMessage!=='')" outlined>
            {{ alertMessage }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-row justify="space-around">
                  <v-btn @click="done" color="blue darken-1" text>Cancel</v-btn>
                  <v-btn :disabled="$v.$invalid" @click="save"
                         color="blue darken-1" text>
                    Set password
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </form>
    </v-card>

    <busy :message="busyMessage"></busy>
  </v-dialog>
</template>

<script>
import Busy from '../Busy'
import axios from 'axios'
import { EVT_SET_USER_PASSWORD, EVT_USER_DONE } from '../../constants'
import { eventBus } from '../../../main'
import {
  required, minLength, sameAs
} from 'vuelidate/lib/validators'

export default {
  name: "Password",
  components: { Busy },

  created () {
    console.log('Created:', this.$options.name)
    this.clear()
    eventBus.$on(EVT_SET_USER_PASSWORD, (rec) => {
      this.item = rec
      this.dialog = true
    })
  },
  beforeDestroy () {
    console.log('Destroy:', this.$options.name)
    this.clear()
  },

  computed: {
    passwordErrors () {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password must not be blank')
      !this.$v.password.minLen && errors.push('Password must be at least 8 characters')
      return errors
    },
    confirmErrors () {
      const errors = []
      if (!this.$v.confirm.$dirty) return errors
      !this.$v.confirm.sameAs && errors.push('Passwords must match')
      return errors
    }
  },

  validations: {
    password: { required, minLen: minLength(8) },
    confirm: { sameAs: sameAs('password') }
  },

  methods: {
    showError (msg) {
      this.alertMessage = msg
      this.alertType = 'error'
      setTimeout(() => (this.alertMessage = ''), 5000)
    },
    showSuccess (msg) {
      this.alertMessage = msg
      this.alertType = 'success'
      setTimeout(() => {
        this.alertMessage = ''
        this.done()
      }, 3000)
    },

    done () {
      this.clear()
      eventBus.$emit(EVT_USER_DONE)
      this.dialog = false
    },
    clear () {
      this.busyMessage = ''
      this.alertMessage = ''
      this.password = ''
      this.confirm = ''
      this.showPassword = false
      this.$v.$reset()
    },

    save () {
      console.log('Save', this.item)
      if (this.$v.$invalid) {
        this.showError("Please correct the errors before setting password.")
        return
      }
      this.busyMessage = 'Setting password...'
      let rec = {
        user_id: this.item.id,
        password: this.password
      }
      axios.put('/user/password', rec)
          .then(res => {
            console.log('Put', res)
            this.busyMessage = ''
            this.showSuccess('Password successfully changed.')
          })
          .catch(err => {
            console.log(err.toString())
            this.busyMessage = ''
            this.showError(`Error changing user password: ${err.toString()}`)
          })
    }
  },

  data () {
    return {
      dialog: false,
      busyMessage: '',
      alertMessage: '',
      alertType: 'error',
      item: {
        id: -1,
        username: '',
        last: '',
        first: ''
      },
      password: '',
      confirm: '',
      username: '',
      showPassword: false
    }
  }
}
</script>

<style scoped>

</style>
