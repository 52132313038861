<template>
  <v-dialog max-width="500px" v-model="dialog">

    <v-card>
      <form>

        <v-card-title>
          <span class="headline">{{ title }}</span>
        </v-card-title>

        <form>
          <v-card-text>
            <v-container grid-list-md>
              <v-row>
                <v-text-field
                    :error-messages="usernameErrors"
                    @blur="$v.item.username.$touch()"
                    @input="$v.item.username.$touch()"
                    autocomplete="username email"
                    filled
                    label="Email"
                    v-model="item.username"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                    :error-messages="lastErrors"
                    @blur="$v.item.last.$touch()"
                    @input="$v.item.last.$touch()"
                    autocomplete="lname"
                    filled
                    label="Last name"
                    v-model="item.last"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                    :error-messages="firstErrors"
                    @blur="$v.item.first.$touch()"
                    @input="$v.item.first.$touch()"
                    autocomplete="fname"
                    filled
                    label="First name"
                    v-model="item.first"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                    :error-messages="initialsErrors"
                    @blur="$v.item.initials.$touch()"
                    @input="$v.item.initials.$touch()"
                    filled
                    label="Initials"
                    v-mask="['aa', 'aaa', 'aaaa']"
                    v-model="item.initials"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-text-field
                    :error-messages="mobileErrors"
                    @blur="$v.item.mobile.$touch()"
                    @input="$v.item.mobile.$touch()"
                    autocomplete="mobile"
                    filled
                    label="Mobile"
                    v-mask="'###-###-####'"
                    v-model="item.mobile"
                ></v-text-field>
              </v-row>
              <v-row>
                <v-select
                    :items="rolesList"
                    filled
                    hint="Roles"
                    item-text="name"
                    item-value="abbr"
                    label="Roles"
                    persistent-hint
                    single-line
                    v-model="item.roles"
                ></v-select>
              </v-row>
            </v-container>

            <v-alert :value="(errorMessage!='')" outlined type="error">
              {{ errorMessage }}
            </v-alert>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="done" color="blue darken-1" text>Cancel</v-btn>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-btn :disabled="$v.$invalid" @click="save"
                   color="blue darken-1" text>
              Save
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </form>

      </form>
    </v-card>

    <busy :message="busyMessage"></busy>
  </v-dialog>
</template>

<script>
import Busy from '../Busy'
import axios from 'axios'
import { EVT_USER_DONE, EVT_EDIT_USER } from '../../constants'
import { eventBus } from '../../../main'
import { email, required, minLength } from 'vuelidate/lib/validators'
import { mask } from 'vue-the-mask'

// validator for US/Canadian phone num, like 501-867-5309
const phone = (value) => /^[2-9]\d{2}-[2-9]\d{2}-\d{4}$/.test(value)

export default {
  name: "Edit",
  components: { Busy },
  directives: { mask },

  created () {
    console.log('Created:', this.$options.name)
    this.clear()
    eventBus.$on(EVT_EDIT_USER, (rec) => {
      this.item = rec
      if (rec.id <= 0) {
        this.title = 'New user'
        this.item.roles = 'M' // default to Manager (lowest level)
      } else {
        this.title = `Edit user ${rec.first} ${rec.last}`
      }
      this.dialog = true
    })
  },
  beforeDestroy () {
    console.log('Destroy:', this.$options.name)
    this.clear()
  },

  computed: {
    usernameErrors () {
      const errors = []
      if (!this.$v.item.username.$dirty) return errors
      !this.$v.item.username.email && errors.push('Must be valid e-mail')
      !this.$v.item.username.required && errors.push('E-mail is required')
      return errors
    },
    lastErrors () {
      const errors = []
      if (!this.$v.item.last.$dirty) return errors
      !this.$v.item.last.required && errors.push('Last name is required')
      return errors
    },
    firstErrors () {
      const errors = []
      if (!this.$v.item.first.$dirty) return errors
      !this.$v.item.first.required && errors.push('First name is required')
      return errors
    },
    initialsErrors () {
      const errors = []
      if (!this.$v.item.initials.$dirty) return errors
      !this.$v.item.initials.minLen && errors.push('Initials must be at least 2 characters')
      !this.$v.item.initials.required && errors.push('Initials are required')
      return errors
    },
    mobileErrors () {
      const errors = []
      if (!this.$v.item.mobile.$dirty) return errors
      !this.$v.item.mobile.phone && errors.push('Must be valid phone number')
      // !this.$v.item.mobile.required && errors.push('Mobile number is required')
      return errors
    }
  },

  validations: {
    item: {
      username: { required, email },
      last: { required },
      first: { required },
      initials: { required, minLen: minLength(2) },
      mobile: { phone }
    }
  },

  methods: {
    showAlert (msg) {
      this.errorMessage = msg
      setTimeout(() => (this.errorMessage = ''), 5000)
    },

    done () {
      this.clear()
      eventBus.$emit(EVT_USER_DONE)
      this.dialog = false
    },
    clear () {
      this.busyMessage = ''
      this.errorMessage = ''
      this.$v.$reset()
    },

    save () {
      console.log('Save', this.item)
      this.busyMessage = 'Saving...'
      this.item.roles = this.selectedRole
      if (this.item.id > 0) {
        axios.patch('/user/edit', this.item)
            .then(res => {
              console.log('Patch', res)
              this.done()
            })
            .catch(error => {
              console.log(error.toString())
              this.busyMessage = ''
              this.showAlert(`Error unable to save edited user record: ${error}`)
            })
      } else {
        axios.post('/user/add', this.item)
            .then(res => {
              console.log('Post', res)
              this.done()
            })
            .catch(error => {
              console.log(error.toString())
              this.busyMessage = ''
              this.showAlert(`Error unable to save new user: ${error}`)
            })
      }
    }
  },

  data () {
    return {
      dialog: false,
      busyMessage: '',
      errorMessage: '',
      title: '',

      item: {
        id: -1,
        username: '',
        last: '',
        first: '',
        initials: '',
        mobile: '',
        roles: ''
      },
      rolesList: [
        { name: 'System', abbr: 'SBEM' },
        { name: 'Bookkeeper', abbr: 'BEM' },
        { name: 'Executive', abbr: 'EM' },
        { name: 'Manager', abbr: 'M' }
      ]
    }
  }
}
</script>

<style scoped>

</style>
