<template>
  <div>
    <v-toolbar color="white" flat>
      <v-toolbar-title>Administrative Users</v-toolbar-title>
      <v-divider
              class="mx-2"
              inset
              vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-btn @click="newItem()" class="px-5" color="primary" dark>Add new user</v-btn>

    </v-toolbar>

    <v-data-table
            :headers="headers"
            :items="items"
            dense
            disable-pagination
            hide-default-footer
    >
      <template v-slot:body="{ items }">
        <tbody>
        <tr :key="item.id" v-for="item in items">
          <td class="text-left">
            {{ item.last }}, {{ item.first }}
          </td>
          <td>
            {{ item.initials }}
          </td>
          <td>
            {{ item.username }}
          </td>
          <td>
            {{ item.mobile }}
          </td>
          <td>
            {{ item.roles }}
          </td>
          <td class="text-right">
            {{prettyDT(item.seen) }}
          </td>
          <td class="justify-center layout">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="editItem(item)" class="mr-2" small v-on="on">
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit user</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="setPassword(item)" class="mr-2" small v-on="on">
                  mdi-key-variant
                </v-icon>
              </template>
              <span>Set user's password</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon @click="confirmDelete(item)" class="mr-2" small v-on="on">
                  mdi-trash-can
                </v-icon>
              </template>
              <span>Delete user</span>
            </v-tooltip>
          </td>
        </tr>
        <tr>
          <td class="font-weight-bold">Total admin users: {{ items.length }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        </tbody>
      </template>

    </v-data-table>

    <v-row>
      <v-col>
        <v-btn @click="refresh" class="white--text mx-6" color="primary">
          <v-icon dark left>mdi-refresh</v-icon>
          Refresh
        </v-btn>
      </v-col>
      <v-col>
        <v-alert :value="(errorMessage!='')" outlined type="error">
          {{ errorMessage }}
        </v-alert>
      </v-col>
    </v-row>


    <confirm-delete></confirm-delete>
    <password></password>
    <edit></edit>
    <busy :message="busyMessage"></busy>

  </div>
</template>

<script>
  import Password from './Password'
  import ConfirmDelete from './ConfirmDelete'
  import Edit from './Edit'
  import Busy from '../Busy'
  import { eventBus } from '../../../main'
  import {
    EVT_CONFIRM_DELETE_USER, EVT_USER_DONE,
    EVT_EDIT_USER, EVT_SET_USER_PASSWORD
  } from '../../constants'
  import { prettyDT } from '../utils'
  import axios from 'axios'

  export default {
    name: 'Users',
    components: {
      Password,
      ConfirmDelete,
      Edit,
      Busy
    },

    created () {
      console.log('Created:', this.$options.name)
      this.refresh()
      eventBus.$on(EVT_USER_DONE, () => {
        console.log("EVT")
        this.refresh()
      })
    },
    beforeDestroy () {
      console.log('Destroy:', this.$options.name)
      this.busyMessage = ''
      this.errorMessage = ''
    },

    computed: {},

    methods: {
      prettyDT,

      refresh () {
        console.log('REFRESH users.')
        if (!this.$store.getters.loggedIn) {
          return
        }
        this.busyMessage = 'Loading...'
        axios.get('/user/list')
            .then(res => {
              this.items = res.data
              console.log('Users:', this.items)
              this.busyMessage = ''
            })
            .catch(error => {
              this.items = [] // no users
              console.log(error.toString())
              this.busyMessage = ''
              this.showAlert('Error unable to refresh users payroll. ' + error.toString())
            })
      },

      newItem () {
        console.log('New user')
        let rec = {
          id: -1,
          username: '',
          last: '', // empty user record
          first: '',
          initials: '',
          mobile: '',
          roles: ''
        }
        eventBus.$emit(EVT_EDIT_USER, rec)
      },
      editItem (item) {
        console.log('Edit user:', item.id, item)
        let rec = { // only send the needed fields
          id: item.id,
          username: item.username,
          last: item.last,
          first: item.first,
          initials: item.initials,
          mobile: item.mobile,
          roles: item.roles
        }
        eventBus.$emit(EVT_EDIT_USER, rec)
      },

      confirmDelete (item) {
        console.log('Confirm delete:', item)
        eventBus.$emit(EVT_CONFIRM_DELETE_USER, item)
      },

      setPassword (item) {
        console.log('Set password:', item.id, item)
        let rec = {
          id: item.id,
          username: item.username,
          last: item.last,
          first: item.first
        }
        eventBus.$emit(EVT_SET_USER_PASSWORD, rec)
      },

      showAlert (msg) {
        this.errorMessage = msg
        setTimeout(() => (this.errorMessage = ''), 5000)
      }
    },

    data () {
      return {
        busyMessage: '',
        errorMessage: '',

        headers: [
          { text: 'Name', value: 'last' },
          { text: 'Initials', value: 'initials' },
          { text: 'Email', value: 'username' },
          { text: 'Mobile', value: 'mobile' },
          { text: 'Roles', value: 'roles' },
          { text: 'Last Seen', align: 'right', value: 'seen' },
          { text: 'Edit', align: 'center', value: 'edit', sortable: false }
        ],
        items: []
      }
    }
  }
</script>

<style scoped>

</style>
