<template>

  <v-dialog max-width="440" v-model="dialog">
    <v-card>
      <v-card-title class="headline">Delete this user?</v-card-title>

      <v-card-text>
        Delete user {{ item.first}} {{ item.last }}?
        This action is irreversible.
      </v-card-text>

      <v-card-actions>
        <v-btn @click="done()" color="primary" text>Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="deleteUser()" color="primary" text>Delete user</v-btn>
      </v-card-actions>

      <v-alert :value="(errorMessage!='')" outlined type="error">
        {{ errorMessage }}
      </v-alert>

    </v-card>

    <busy :message="busyMessage"></busy>

  </v-dialog>
</template>

<script>
  import { eventBus } from '../../../main'
  import { EVT_CONFIRM_DELETE_USER, EVT_USER_DONE } from '../../constants'
  import axios from 'axios'
  import Busy from '../Busy'

  export default {
    name: "ConfirmDelete",
    components: {
      Busy
    },
    created () {
      console.log('Created:', this.$options.name)
      eventBus.$on(EVT_CONFIRM_DELETE_USER, (rec) => {
        this.item = rec
        this.dialog = true
      })
    },
    beforeDestroy () {
      console.log('Destroy:', this.$options.name)
      this.busyMessage = ''
      this.errorMessage = ''
    },

    methods: {
      deleteUser () {
        console.log('Delete:', this.item)
        this.busyMessage = 'Deleting...'
        axios.delete(`/user/delete/${this.item.id}`)
            .then(res => {
              console.log('Delete:', res)
              this.done()
            })
            .catch(err => {
              console.log(err.toString())
              this.busyMessage = ''
              this.showAlert(`Error unable to delete user: ${err.toString()}`)
            })
      },
      done () {
        this.busyMessage = ''
        this.errorMessage = ''
        eventBus.$emit(EVT_USER_DONE)
        this.dialog = false
      },
      showAlert (msg) {
        this.errorMessage = msg
        setTimeout(() => (this.errorMessage = ''), 5000)
      }
    },

    data () {
      return {
        dialog: false,
        busyMessage: '',
        errorMessage: '',

        item: {}
      }
    }

  }
</script>

<style scoped>

</style>
